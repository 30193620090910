<template>
  <div class="xl:flex py-6 pr-4 grow-0 shrink-0">
    <div class="2xl:w-[384px] xl:w-[300px] max-w-sm pr-3 shrink-0">
      <div class="first-letter:capitalize">{{ props.title }}</div>
      <p class="text-xs mt-1">
        <slot name="description"> </slot>
      </p>
    </div>

    <div class="w-full grow xl:mt-0 mt-5 space-y-9">
      <slot> </slot>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
});
</script>
