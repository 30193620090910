<template>
  <div
    class="flex"
    :class="[`space-x-${props.gap}`, `justify-${props.justify}`]"
  >
    <slot></slot>
  </div>
</template>

<script setup>
const props = defineProps({
  gap: {
    type: String,
    default: "2",
  },
  align: {
    type: String,
    default: null,
    validator: (value) => {
      const allowedValues = ["start", "center", "end", "baseline", "stretch"];
      return allowedValues.includes(value);
    },
  },
  justify: {
    type: String,
    default: null,
    validator: (value) => {
      const allowedValues = [
        "start",
        "center",
        "end",
        "between",
        "around",
        "evenly",
        "stretch",
      ];
      return allowedValues.includes(value);
    },
  },
});
</script>

<style scoped></style>
