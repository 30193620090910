<template>
  <div class="grid grid-cols-12" :class="`gap-${props.gap}`">
    <slot></slot>
  </div>
</template>

<script setup>
const props = defineProps({
  gap: {
    type: String,
    required: false,
    default: "4",
  },
});
</script>
