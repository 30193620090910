import { useRouter } from "vue-router";
import config from "@/config";
import {
  useAuthStore,
  useOrgUnitsStore,
  useUserPreferenceStore,
  useCustomerStore,
  useUserStore,
} from "@/stores";
import { computed } from "vue";

export function useRoutesHelper() {
  const authStore = useAuthStore();
  const customerStore = useCustomerStore();

  const handlePostLoginRedirection = async (
    router,
    route,
    defaultRedirection = "no-permission"
  ) => {
    const userPreferenceStore = useUserPreferenceStore();
    const orgUnitsStore = useOrgUnitsStore();
    const userStore = useUserStore();

    const modules = { ...config.MODULES, ...config.SETTING_GROUPS };
    const routesWithPrivileges = [];

    if (
      orgUnitsStore?.items?.length === 0 &&
      customerStore?.activeCustomer?.googleCustomerId
    ) {
      await orgUnitsStore.listItems();
      await userPreferenceStore.fetchSelectedOrgUnit();
    }

    if (userStore?.items?.length === 0) {
      await userStore.listItems();
    }

    Object.values(modules).map((module) => {
      const routeList = getModuleRoutes(module.value, router);

      routeList.forEach((route) => {
        if (authStore.hasPrivilege(route.meta?.requiredPrivileges)) {
          routesWithPrivileges.push(route);
        }
      });
    }, []);

    await router.push(
      route.query.redirect || {
        name: routesWithPrivileges[0]?.name ?? defaultRedirection,
      }
    );
  };

  const getModuleRoutes = (module, router = useRouter()) =>
    router
      .getRoutes()
      .filter(
        (route) => route.meta?.module?.value === module && !route.meta?.parent
      );

  const isCurrentRoute = (route, routeName) =>
    route.matched.some(
      ({ name, meta }) => name === routeName || meta.parent === routeName
    );

  const hasActiveSubscription = computed(
    () =>
      customerStore.activeCustomer?.subscriptionProducts.length > 0 &&
      customerStore.activeCustomer?.stripeSubscriptionStatus === "active"
  );

  const hasActiveTrial = computed(() => {
    if (customerStore.activeCustomer?.isPaidSubscription === true) {
      return false;
    }

    let isTrialActive = false;
    customerStore.customerTrials.forEach((trial) => {
      if (new Date(trial.endDate) > new Date() && trial.status === "ACTIVE") {
        isTrialActive = true;
      }
    });

    return isTrialActive;
  });

  const hasItemPrivilege = (item) =>
    authStore.hasPrivilege(item.meta?.requiredPrivileges) &&
    customerStore.activeCustomer?.googleCustomerId &&
    customerStore.activeCustomer?.initSyncStatus === "COMPLETED" &&
    (hasActiveSubscription.value ||
      hasActiveTrial.value ||
      customerStore.activeCustomer?.partnerId ||
      customerStore.activeCustomer?.isPaidSubscription === true);

  return {
    getModuleRoutes,
    handlePostLoginRedirection,
    isCurrentRoute,
    hasItemPrivilege,
  };
}
