<template>
  <div
    class="absolute w-full -mx-2 flex items-center justify-center z-10 h-full"
    data-testid="vloadingblur-wrapper"
  >
    <VRow class="w-fit items-center justify-center">
      <img
        class="w-8 h-8 loading_animation"
        src="@/assets/loading.svg"
        alt="loading"
        data-testid="vloadingblur-logo"
      />
      <p class="ml-8 loading" data-testid="vloadingblur-text">
        {{ props.loadingText }}
      </p>
    </VRow>
  </div>
  <div class="blur" data-testid="vloadingblur-content">
    <slot></slot>
  </div>
</template>

<script setup>
const props = defineProps({
  loadingText: {
    type: String,
    required: false,
    default: "Loading",
  },
});
</script>

<style scoped>
.loading_animation {
  -webkit-animation: spin 0.5s linear infinite;
  -moz-animation: spin 0.5s linear infinite;
  animation: spin 0.5s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loading:after {
  content: ".";
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: rgb(0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 rgb(0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 rgb(0, 0, 0), 0.5em 0 0 rgb(0, 0, 0);
  }
}
</style>
