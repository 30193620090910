<template>
  <TransitionRoot as="template" :show="Boolean(props.modelValue)">
    <div>
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gradient-to-b opacity-30 from-gray-200 to-primary-200 bg-opacity-10 transition-opacity"
        />
      </TransitionChild>

      <div
        class="inset-0 z-10 overflow-y-auto"
        :class="props.full ? 'fixed' : ''"
      >
        <div
          class="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0 overflow-hidden"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div class="">
              <img
                class="w-12 h-12 mx-auto loading_animation"
                src="@/assets/loading.svg"
                alt="loading"
              />
              <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <h4 class="loading_text">
                  <strong class="loading">{{ props.loadingText }}</strong>
                </h4>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </div>
  </TransitionRoot>
</template>

<script setup>
// import ModalWarningIcon from "@/components/ModalWarningIcon.vue";
import { TransitionChild, TransitionRoot } from "@headlessui/vue";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: false,
  },
  loadingText: {
    type: String,
    required: false,
    default: "Loading",
  },
  full: {
    type: Boolean,
    required: false,
    default: false,
  },
});
</script>

<style scoped>
.loading_animation {
  -webkit-animation: spin 0.5s linear infinite;
  -moz-animation: spin 0.5s linear infinite;
  animation: spin 0.5s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loading:after {
  content: ".";
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: rgb(0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 rgb(0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 rgb(0, 0, 0), 0.5em 0 0 rgb(0, 0, 0);
  }
}
</style>
