<template>
  <button
    type="button"
    class="-my-1.5 mx-2 flex flex-none items-center justify-center rounded-full p-1.5 text-gray-700 hover:bg-gray-25 hover:text-gray-900"
  >
    <span class="sr-only">Previous month</span>
    <VIcon v-if="props.icon === 'chevron-left'" name="chevron_left" />
    <VIcon v-if="props.icon === 'chevron-right'" name="chevron_right" />
  </button>
</template>

<script setup>
const props = defineProps({
  icon: { type: String, required: true },
});
</script>
