<template>
  <div v-if="props.status === 'COMPLETE'">
    <div
      class="relative flex h-8 w-8 items-center justify-center rounded-full bg-primary-600 hover:bg-primary-900"
    >
      <VIcon name="check" class="text-white" />
      <span class="sr-only">{{ step.name }}</span>
    </div>
  </div>
  <div
    v-else-if="props.status === 'CURRENT'"
    condition="props.status === 'CURRENT'"
  >
    <div
      class="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-primary-600 bg-white"
      aria-current="step"
    >
      <span
        class="h-2.5 w-2.5 rounded-full bg-primary-600"
        aria-hidden="true"
      />
      <span class="sr-only">{{ step.name }}</span>
    </div>
  </div>
  <div v-else>
    <div
      class="border-gray-300 hover:border-gray-400 group relative flex h-8 w-8 items-center justify-center rounded-full border-2 bg-white"
    >
      <span
        class="bg-transparent group-hover:bg-gray-300 h-2.5 w-2.5 rounded-full"
        aria-hidden="true"
      />
      <span class="sr-only">{{ step.name }}</span>
    </div>
  </div>
  <!-- line -->
  <div class="inset-0 flex flex-grow items-center px-2" v-if="!props.last">
    <div class="bg-gray-200 h-0.5 w-full" />
  </div>
</template>

<script setup>
const props = defineProps({
  step: {
    type: Object,
    required: true,
  },
  status: {
    type: String,
    required: true,
  },
  last: {
    type: Boolean,
    required: true,
  },
});
</script>
