<template>
  <VMenu
    :menu-items="props.menuItems"
    :item="props.item"
    :position="props.position"
  >
    <div
      class="flex items-center mt-1 p-1 rounded-full bg-white hover:text-gray-600 hover:bg-google-blue-200 focus:outline-none"
    >
      <span class="sr-only">Open options</span>
      <VIcon name="more_vert" />
    </div>
  </VMenu>
</template>

<script setup>
const props = defineProps({
  menuItems: {
    type: Array,
    default: () => [],
  },
  item: {
    type: Object,
    default: () => {},
  },
  position: {
    type: String,
    default: "bottom-left",
    validator: (value) => {
      const validVariants = [
        "top-left",
        "top-right",
        "bottom-left",
        "bottom-right",
        "left",
        "right",
      ];
      if (!validVariants.includes(value)) {
        console.warn(
          `Invalid position: ${value}. Defaulting to "bottom-left".`
        );
        return false;
      }
      return true;
    },
  },
});
</script>
