<template>
  <VCheckbox
    :xl="props.xl"
    :xs="props.xs"
    :label="props.label"
    :description="props.description"
    :disabled="props.disabled"
    :radio="true"
    :modelValue="props.modelValue"
    :value="props.value"
    :selectAll="props.selectAll"
  />
</template>

<script setup>

const props = defineProps({
  label: {
    type: String,
    default: "Select",
  },
  description: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: [Boolean, String],
  },
  value: {
    type: String,
    default: null,
  },
  selectAll: {
    type: Boolean,
    default: false,
  },
  xl: {
    type: Boolean,
    default: false,
  },
  xs: {
    type: Boolean,
    default: false,
  },
});
</script>
