<template>
  <div>
    <slot name="label" v-if="props.label">
      <FormInputLabel :label="props.label" />
    </slot>
    <div
      class="h-10 rounded border border-gray-50 bg-white px-3 py-2 focus-within:border-primary-500 focus-within:ring-2 focus-within:ring-primary-500/20"
      :class="[
        props.area ? ' h-auto' : 'h-10',
        props.error
          ? 'border-error focus-within:!border-error focus-within:!ring-error-500/20'
          : '',
        props.disabled ? 'cursor-not-allowed !bg-disabled' : '',
        props.label ? 'mt-1' : '',
      ]"
    >
      <div class="flex">
        <VIcon
          v-if="props.icon"
          class="mr-2 flex text-gray-800"
          :class="props.iconAppend ? 'order-last' : ''"
          :name="props.icon"
          @click="emit('iconClick')"
        />
        <slot name="default"> </slot>
        <!-- TODO: Make iconbutton -->
        <VIcon
          v-if="props.clearable"
          class="ml-2 mr-2 flex text-gray-800"
          name="close"
          @click="emit('clear')"
        />
      </div>
    </div>
    <div
      v-if="!props.noError"
      class="mt-0.5 h-5 text-xs text-error"
      :class="props.error ? 'shake-animation' : ''"
    >
      <slot name="info"> </slot>
    </div>
  </div>
</template>

<script setup>
import FormInputLabel from "@/components/helpers/FormInputLabel.vue";

const emit = defineEmits(["clear", "iconClick"]);

const props = defineProps({
  label: {
    type: String,
    required: false,
    default: "",
  },
  area: {
    type: Boolean,
    default: false,
  },
  error: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  clearable: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: "",
  },
  iconAppend: {
    type: Boolean,
    default: false,
  },
  noError: {
    type: Boolean,
    default: false,
  },
});
</script>

<style>
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-1px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(2px, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.shake-animation {
  animation: shake 0.6s ease-in-out;
  animation-iteration-count: 0.5;
}
</style>
