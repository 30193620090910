<template>
  <div class="border-gray-200 flex w-full border-t-2 p-3 space-x-2">
    <VButton label="Cancel" class="w-1/2" @click="emit('cancel')" />
    <VButton
      variant="primary"
      label="Ok"
      class="w-1/2"
      @click="emit('onConfirm')"
      :disabled="props.disableSubmit"
    />
  </div>
</template>

<script setup>
const emit = defineEmits(["cancel", "onConfirm"]);

const props = defineProps({
  disableSubmit: { type: Boolean, default: false },
});
</script>
