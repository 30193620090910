<template>
  <label
    for="name"
    class="inline-block px-1 text-sm font-medium text-gray-700 focus:ring-0"
    >{{ props.label }}</label
  >
</template>

<script setup>
const props = defineProps({
  label: { type: String, default: "" },
});
</script>
